.container {
  display: flex;
  align-items: flex-start;
  border-radius: 6px;
  background: #fff;
  height: 100vh;
}

.layoutFrame1 {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--slate-900, #0f172a);
  width: 47%;
}

.layoutFrame2 {
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  flex: 1 0 0;
  align-self: stretch;
  width: 53%;
}

.introFrame {
  width: 570px;
  margin: auto;
  display: flex;
  padding: 0px 16px 65px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  flex: 1 0 0;
  align-self: stretch;
}

.introContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 63px;
  align-self: stretch;
}

.introText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.introText > h2 {
  color: var(--white, #fff);

  /* h2 */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.225px;
}

.valuePropsFrame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.valuePropsFrame > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.valuePropsFrame > div > p {
  color: var(--slate-200, #e2e8f0);

  /* p-ui-medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.partnersFrame {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.partnersFrame > p {
  color: var(--slate-400, #94a3b8);
  text-align: center;
}

.partnersLogos {
  display: flex;
  align-items: flex-end;
  gap: 40px;
}

@media screen and (max-width: 1200px) {
  .introFrame {
    width: 390px;
  }
}

@media screen and (max-width: 900px) {
  .layoutFrame1 {
    display: none;
  }
}
